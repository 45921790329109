<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { computed, reactive, ref, unref } from 'vue'
import { useMutation, useQuery } from '@vue/apollo-composable'
import {
  CreateTool,
  ToolsViewCreateMutation,
  ToolsViewCreateMutationVariables,
  ToolsViewDataQuery,
  ToolsViewDataQueryVariables,
  ToolsViewDeleteMutation,
  ToolsViewDeleteMutationVariables,
  ToolsViewUpdateMutation,
  ToolsViewUpdateMutationVariables,
  UpdateTool,
} from '@/generated/graphql'
import gql from 'graphql-tag'
import useVuelidate from '@vuelidate/core'
import { KeysOfType } from '@/app'
import TextField from '@/components/input/TextField.vue'
import { normalDescription, normalName, required } from '@/validation'
import ItemEditIcon from '@/components/button/ButtonEdit.vue'
import ItemDeleteIcon from '@/components/button/ButtonDelete.vue'
import TextAreaField from '@/components/input/TextAreaField.vue'
import SitePicker from '@/components/input/SitePicker.vue'
import MultiImagePicker from '@/components/input/MultiImagePicker.vue'
import { ComponentExposed } from 'vue-component-type-helpers'
import UpsertDialog from '@/components/dialogs/UpsertDialog.vue'
import DeleteDialogNext from '@/components/dialogs/AsyncDeleteDialog.vue'
import AsyncDeleteDialog from '@/components/dialogs/AsyncDeleteDialog.vue'
import DataTable from '@/components/dataTable/DataTable.vue'
import DataTableSearch from '@/components/dataTable/DataTableSearch.vue'
import DataTableCreateButton from '@/components/dataTable/DataTableCreateButton.vue'
import IconArchived from '@/components/icons/IconArchived.vue'
import Checkbox from '@/components/input/Checkbox.vue'

type LocalTool = ToolsViewDataQuery['site']['site']['tools'][0]

const { t } = useI18n()

const siteId = ref<string>()
const fetchQuery = useQuery<ToolsViewDataQuery, ToolsViewDataQueryVariables>(
  gql`
    query ToolsViewData($siteId: ID!) {
      site {
        site(id: $siteId) {
          tools {
            id
            name
            description
            imageUploadIds
            archived
            released
          }
        }
      }
    }
  `,
  () => ({
    siteId: unref(siteId) as string,
  }),
  () => ({
    enabled: !!siteId.value,
  }),
)
const items = computed(() => fetchQuery.result.value?.site.site.tools || [])

const validationRules = {
  name: { required, normalName },
  description: { normalDescription },
}

type CreateModel = Partial<CreateTool> & { imageUploadIds: string[] }
const createModelKeys: KeysOfType<keyof CreateModel> = [
  'id',
  'name',
  'description',
  'siteId',
  'imageUploadIds',
]
const createModel = reactive<CreateModel>({ imageUploadIds: [] })
const createValidation = useVuelidate<CreateModel>(validationRules, createModel)
const createMutation = useMutation<ToolsViewCreateMutation, ToolsViewCreateMutationVariables>(gql`
  mutation ToolsViewCreate($command: CreateTool!) {
    site {
      createTool(command: $command) {
        id
      }
    }
  }
`)
const createDialog = ref<ComponentExposed<typeof UpsertDialog<() => Promise<unknown>>>>()
const doCreate = async () => {
  createModelKeys.forEach((k) => {
    ;(createModel as Record<string, unknown>)[k] = undefined
  })
  createModel.siteId = siteId.value
  createModel.imageUploadIds = []

  await createDialog.value?.open(() =>
    createMutation.mutate({ command: createModel as CreateTool }).then(() => fetchQuery.refetch()),
  )
}

type UpdateModel = Partial<UpdateTool> & { imageUploadIds: string[] }
const updateModelKeys: KeysOfType<keyof UpdateModel> = [
  'id',
  'name',
  'description',
  'imageUploadIds',
  'archived',
]
const updateModel = reactive<UpdateModel>({ imageUploadIds: [] })
const updateValidation = useVuelidate<CreateModel>(validationRules, updateModel)
const updateMutation = useMutation<ToolsViewUpdateMutation, ToolsViewUpdateMutationVariables>(gql`
  mutation ToolsViewUpdate($command: UpdateTool!) {
    site {
      updateTool(command: $command) {
        id
      }
    }
  }
`)
const updateDialog = ref<ComponentExposed<typeof UpsertDialog<() => Promise<unknown>>>>()
const updateIsReleased = ref(false)
const doUpdate = async (item: LocalTool) => {
  updateModelKeys.forEach((k) => {
    ;(updateModel as Record<string, unknown>)[k] = item[k as keyof LocalTool]
  })
  updateIsReleased.value = item.released
  await updateDialog.value?.open(() =>
    updateMutation.mutate({ command: updateModel as UpdateTool }).then(() => fetchQuery.refetch()),
  )
}

const deleteMutation = useMutation<ToolsViewDeleteMutation, ToolsViewDeleteMutationVariables>(gql`
  mutation ToolsViewDelete($id: ID!) {
    site {
      deleteTool(id: $id)
    }
  }
`)
const deleteDialog = ref<ComponentExposed<typeof DeleteDialogNext>>()
const doDelete = async (site: LocalTool) => {
  await deleteDialog.value?.open(site.name, () =>
    deleteMutation.mutate({ id: site.id }).then(() => fetchQuery.refetch()),
  )
}

const search = ref('')
const headers = [
  {
    key: 'name',
    title: t('entity.tool.field.name'),
  },
  {
    key: 'description',
    title: t('entity.tool.field.description'),
  },
  {
    key: 'archived',
    title: t('entity.tool.field.archived'),
    width: 100,
  },
  {
    key: 'actions',
    width: '100',
    sortable: false,
    align: 'end',
  },
]
const sort = [{ key: 'name', order: 'asc' }]
</script>

<template>
  <h1>{{ t('entity.tool.plural') }}</h1>
  <site-picker v-model="siteId" with-archived focused>
    <template #prepend>
      <v-icon icon="factory" />
    </template>
  </site-picker>

  <data-table
    v-if="!!siteId"
    :items="items"
    :headers="headers"
    :loading="fetchQuery.loading.value"
    :sort-by="sort"
    :search="search"
    density="comfortable"
  >
    <template #header.actions>
      <data-table-search v-model="search" />
      <data-table-create-button v-if="!!siteId" @click="doCreate" />
    </template>
    <template #item.actions="{ item }: { item: LocalTool }">
      <item-edit-icon @click="doUpdate(item)" />
      <item-delete-icon
        :disable-reason="item.released ? t('entity.tool.disableDeleteBecause') : undefined"
        @click="doDelete(item)"
      />
    </template>
    <template #item.archived="{ item }: { item: LocalTool }">
      <icon-archived :is-archived="item.archived" />
    </template>
  </data-table>

  <async-delete-dialog ref="deleteDialog" :warning="t('entity.tool.deleteWarning')" hindrance />

  <upsert-dialog ref="createDialog" type="create" :validation="createValidation">
    <text-field
      v-model="createModel.name"
      :label="t('entity.tool.field.name')"
      required
      :validation="createValidation.name"
    />
    <text-area-field
      v-model="createModel.description"
      :label="t('entity.tool.field.description')"
      :validation="createValidation.description"
    />
    <multi-image-picker
      v-model="createModel.imageUploadIds"
      :label="t('entity.tool.field.images')"
    />
  </upsert-dialog>

  <upsert-dialog ref="updateDialog" type="update" :validation="updateValidation">
    <v-alert v-if="updateIsReleased" type="warning" variant="outlined" class="mb-5">
      {{ t('entity.tool.editWarning') }}
    </v-alert>
    <text-field
      v-model="updateModel.name"
      required
      :label="t('entity.tool.field.name')"
      :validation="updateValidation.name"
    />
    <text-area-field
      v-model="updateModel.description"
      :label="t('entity.tool.field.description')"
      :validation="updateValidation.description"
    />
    <multi-image-picker
      v-model="updateModel.imageUploadIds"
      :label="t('entity.tool.field.images')"
    />
    <checkbox v-model="updateModel.archived" :label="t('entity.tool.field.archived')" />
  </upsert-dialog>
</template>

<style scoped lang="scss"></style>
