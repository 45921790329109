<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useQuery } from '@vue/apollo-composable'
import { CheckDialogDataQuery, CheckDialogDataQueryVariables } from '@/generated/graphql'
import gql from 'graphql-tag'
import { computed } from 'vue'
import DateTime from '@/components/DateTime.vue'
import NumericCheck from '@/components/check/NumericCheck.vue'
import Markdown from '@/components/Markdown.vue'
import IconChecked from '@/components/icons/IconChecked.vue'

const model = defineModel<string>({ required: false })

const { t } = useI18n()

type LocalCheck = CheckDialogDataQuery['shopFloor']['check']
type LocalConfirmCheck = LocalCheck & { __typename: 'ConfirmCheck' }
type LocalNumericCheck = LocalCheck & { __typename: 'NumericCheck' }
type LocalStringCheck = LocalCheck & { __typename: 'StringCheck' }
type LocalCheckI = LocalConfirmCheck & LocalNumericCheck & LocalStringCheck

const fetchQuery = useQuery<CheckDialogDataQuery, CheckDialogDataQueryVariables>(
  gql`
    query CheckDialogData($id: ID!) {
      shopFloor {
        check(id: $id) {
          __typename
          ... on CheckI {
            id
            released
            reportingUser {
              id
              firstname
              lastname
            }
            serialNumber
            poNumber
            createdAt
            updatedAt
          }
          ... on ConfirmCheck {
            confirmed
          }
          ... on NumericCheck {
            milliQuantity
          }
          ... on StringCheck {
            text
          }
        }
      }
    }
  `,
  () => ({
    id: model.value,
  }),
  () => ({
    enabled: !!model.value,
  }),
)
const check = computed(() => fetchQuery.result.value?.shopFloor.check)
const generic = computed<LocalCheckI | undefined>(() => check.value)
const numeric = computed<NumericCheck | undefined>(() =>
  generic.value?.__typename == 'NumericCheck' ? generic.value : undefined,
)
const confirm = computed<ConfirmCheck | undefined>(() =>
  generic.value?.__typename == 'ConfirmCheck' ? generic.value : undefined,
)
const string = computed<StringCheck | undefined>(() =>
  generic.value?.__typename == 'StringCheck' ? generic.value : undefined,
)
</script>

<template>
  <v-dialog
    v-if="generic"
    :model-value="!!model"
    width="500"
    v-bind="$attrs"
    scrollable
    @update:model-value="model = undefined"
  >
    <v-card :loading="fetchQuery.loading.value">
      <v-card-title>
        <v-btn
          icon="close"
          variant="flat"
          density="compact"
          class="float-right"
          @click="model = undefined"
        />
      </v-card-title>
      <v-card-text>
        <dl>
          <dd>{{ t('entity.check.field.poNumber') }}</dd>
          <dt>{{ generic.poNumber }}</dt>
          <dd>{{ t('entity.check.field.serialNumber') }}</dd>
          <dt>{{ generic.serialNumber }}</dt>
          <dd>{{ t('entity.check.field.released') }}</dd>
          <dt><icon-checked :value="generic.released" /></dt>
          <dd>{{ t('entity.check.field.poNumber') }}</dd>
          <dt>{{ generic.poNumber }}</dt>
          <dd>{{ t('entity.check.field.createdAt') }}</dd>
          <dt><date-time :model-value="generic.createdAt" /></dt>
          <dd>{{ t('entity.check.field.updatedAt') }}</dd>
          <dt><date-time :model-value="generic.updatedAt" /></dt>
          <dd>{{ t('entity.check.field.reportingUser') }}</dd>
          <dt>{{ generic.reportingUser.firstname }} {{ generic.reportingUser.lastname }}</dt>
          <template v-if="confirm">
            <dd>{{ t('entity.check.field.confirmed') }}</dd>
            <dt><icon-checked :value="confirm.confirmed" /></dt>
          </template>
          <template v-if="string">
            <dd>{{ t('entity.check.field.text') }}</dd>
            <dt><markdown :text="string.text" /></dt>
          </template>
          <template v-if="numeric">
            <dd>{{ t('entity.check.field.milliQuantity') }}</dd>
            <dt>{{ numeric.milliQuantity / 1000 }}</dt>
          </template>
        </dl>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn variant="elevated" color="primary" @click="model = undefined">
          {{ t(`button.close`) }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped lang="scss">
dd {
  font-size: 0.8em;
  margin-top: 0.5em;
  color: #888;
}
</style>
