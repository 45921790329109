<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { ref } from 'vue'

const { t } = useI18n()

const model = ref(false)
</script>

<template>
  <v-btn id="helpActivator" icon="help" variant="text" density="compact" @click="model = true" />
  <v-dialog v-model="model" width="500" location="right">
    <v-card>
      <v-card-title>
        {{ t('component.helpDialog.title') }}
        <v-btn
          variant="flat"
          density="compact"
          icon="close"
          class="float-right"
          @click="model = false"
        />
      </v-card-title>
      <v-card-text>
        <div v-html="t('component.helpDialog.text')" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<style scoped lang="scss">
#helpActivator {
  position: fixed;
  right: 0.1em;
  bottom: 0.1em;
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }
}
</style>
