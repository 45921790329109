import OpenReplayTracker from '@openreplay/tracker'
import { watch } from 'vue'
import { currentOrganization, currentUserAndPermissions } from '@/app'
import { createGraphqlMiddleware } from '@openreplay/tracker-graphql'
import { ApolloLink } from '@apollo/client/core'
import * as Sentry from '@sentry/browser'

type Tracker = {
  trackEvent: (key: string, payload?: unknown) => void
  trackerApolloLink: ApolloLink
}
const setupProductionTracker = (): Tracker => {
  const tracker = new OpenReplayTracker({
    projectKey: import.meta.env.VITE_OPEN_REPLAY_KEY as string,
    revID: import.meta.env.VITE_GIT_SHA as string,
    obscureTextEmails: true,
    obscureInputEmails: true,
    defaultInputMode: 1,
  })

  const handler = tracker.use(createGraphqlMiddleware())

  const trackerApolloLink = new ApolloLink((operation, forward) => {
    operation.setContext({ timerStart: performance.now() })
    return forward(operation).map((result) => {
      const time = performance.now() - operation.getContext().timerStart
      return handler(
        // op kind, name, variables, response, duration (default 0)
        operation.query.kind,
        operation.operationName,
        operation.variables,
        result,
        time,
      )
    })
  })

  tracker
    .start()
    .then(() => {
      Sentry.setTag('openReplaySessionToken', tracker.getSessionToken())
      Sentry.setTag('openReplaySessionURL', tracker.getSessionURL())
    })
    .catch((reason) => {
      console.error(`Could not initialize OpenReplay`, reason)
    })

  watch(
    currentOrganization,
    (v) => {
      tracker.setMetadata('organizationId', v?.id || 'unknown')
      tracker.setMetadata('organizationName', v?.name || 'unknown')
    },
    { immediate: true },
  )
  watch(
    currentUserAndPermissions,
    (v) => {
      tracker.setUserID(v ? `${v.user.firstname} ${v.user.lastname} (${v.user.id})` : 'unknown')
      tracker.setMetadata('permissionsUser', v?.userPermissions.join(',') || 'unknown')
      tracker.setMetadata('permissionsOrg', v?.orgPermissions?.join(',') || 'unknown')
    },
    { immediate: true },
  )

  const trackEvent = (key: string, payload?: unknown) => {
    tracker.event(key, payload, false)
  }

  return {
    trackEvent,
    trackerApolloLink,
  }
}
const setupDevTracker = (): Tracker => {
  const nothing = () => {}
  const trackerApolloLink = new ApolloLink((operation, forward) => forward(operation))

  return {
    trackEvent: nothing,
    trackerApolloLink,
  }
}

const env = import.meta.env.VITE_ENVIRONMENT as string
const tracker = env == 'development' ? setupDevTracker() : setupProductionTracker()

export default function useTracker() {
  return tracker
}
