<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import ArticleName from '@/components/article/ArticleName.vue'

type IssueLinkDisplayBOP = {
  bop: {
    version: number
    productConfiguration: {
      name: string
      product: {
        name: string
      }
    }
    site: {
      name: string
    }
  }
}
type IssueLinkDisplayModule = IssueLinkDisplayBOP & {
  module: {
    name: string
  }
}
type IssueLinkDisplayStep = IssueLinkDisplayModule & {
  step: {
    name: string
  }
}
type IssueLinkDisplaySerialNumber = IssueLinkDisplayStep & {
  poNumber: string
  serialNumber: string
}
type IssueLinkDisplayArticle = {
  article: {
    articleNumber: string
    revision: string
  }
}

const props = defineProps<{
  link:
    | IssueLinkDisplayBOP
    | IssueLinkDisplayModule
    | IssueLinkDisplayStep
    | IssueLinkDisplaySerialNumber
    | IssueLinkDisplayArticle
  shortened?: boolean
}>()
const { t } = useI18n()

const bop = computed(() =>
  (props.link as IssueLinkDisplayBOP).bop ? (props.link as IssueLinkDisplayBOP) : undefined,
)
const module = computed(() =>
  (props.link as IssueLinkDisplayModule).module
    ? (props.link as IssueLinkDisplayModule)
    : undefined,
)
const step = computed(() =>
  (props.link as IssueLinkDisplayStep).step ? (props.link as IssueLinkDisplayStep) : undefined,
)
const serialNumber = computed(() =>
  (props.link as IssueLinkDisplaySerialNumber).serialNumber
    ? (props.link as IssueLinkDisplaySerialNumber)
    : undefined,
)
const article = computed(() =>
  (props.link as IssueLinkDisplayArticle).article
    ? (props.link as IssueLinkDisplayArticle)
    : undefined,
)
</script>

<template>
  <template v-if="bop">
    {{ t('entity.product.singular') }}
    <v-icon icon="navigate_next" />
    {{ bop.bop.productConfiguration.product.name }}
    <v-icon icon="navigate_next" />
    {{ bop.bop.productConfiguration.name }}
    <v-icon icon="navigate_next" />
    {{ bop.bop.site.name }}
    <v-icon icon="navigate_next" />
    {{ bop.bop.version }}
  </template>
  <template v-if="module && !props.shortened">
    <v-icon icon="navigate_next" />
    {{ module.module.name }}
  </template>
  <template v-if="step && !props.shortened">
    <v-icon icon="navigate_next" />
    {{ step.step.name }}
  </template>
  <template v-if="serialNumber && !props.shortened">
    <v-icon icon="navigate_next" />
    {{ serialNumber.poNumber }} - {{ serialNumber.serialNumber }}
  </template>
  <template v-if="article">
    {{ t('entity.article.singular') }}
    <v-icon icon="navigate_next" />
    <article-name :article="article.article" />
  </template>
</template>

<style scoped lang="scss"></style>
