<script setup lang="ts">
import useMarkdown from '@/useMarkdown'
import { computed } from 'vue'

const props = defineProps<{
  text?: string
  clipAround?: number
}>()

const { parseMarkdown } = useMarkdown()
const clippedText = computed(() =>
  props.clipAround ? props.text.indexOf(' ', props.clipAround - 5) : props.text,
)
</script>

<template>
  <div v-if="props.text" class="markdownText" v-html="parseMarkdown(clippedText)" />
</template>

<style lang="scss">
.markdownText {
  p {
    margin-bottom: 1em;
  }
}
</style>
