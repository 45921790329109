<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { computed, reactive, ref } from 'vue'
import { useQuery } from '@vue/apollo-composable'
import {
  CheckSearchQuery,
  CheckViewDataQuery,
  CheckViewDataQueryVariables,
  ConfirmCheck,
  NumericCheck,
  StringCheck,
} from '@/generated/graphql'
import gql from 'graphql-tag'
import DataTable from '@/components/dataTable/DataTable.vue'
import AsyncDeleteDialog from '@/components/dialogs/AsyncDeleteDialog.vue'
import DataTableSearch from '@/components/dataTable/DataTableSearch.vue'
import CheckDialog from '@/components/check/CheckDialog.vue'
import DateTime from '@/components/DateTime.vue'
import IconChecked from '@/components/icons/IconChecked.vue'
import ArticleQuantity from '@/components/article/ArticleQuantity.vue'
import Markdown from '@/components/Markdown.vue'

type LocalCheck = CheckViewDataQuery['shopFloor']['checks'][0]
const { t } = useI18n()

const searchQuery = reactive<CheckSearchQuery>({})
const fetchQuery = useQuery<CheckViewDataQuery, CheckViewDataQueryVariables>(
  gql`
    query CheckViewData($query: CheckSearchQuery!) {
      shopFloor {
        checks(query: $query) {
          ... on CheckI {
            id
            poNumber
            serialNumber
            released
            updatedAt
          }
          ... on StringCheck {
            text
          }
          ... on NumericCheck {
            milliQuantity
          }
          ... on ConfirmCheck {
            confirmed
          }
        }
      }
    }
  `,
  () => ({
    query: searchQuery,
  }),
  () => ({
    debounce: 500,
  }),
)
const items = computed(() => fetchQuery.result.value?.shopFloor.checks || [])
const tableItems = computed(() => items.value.slice(0, 100))

const selectedCheckId = ref<string>()
const selectCheck = (_: unknown, row: { item: LocalCheck }) => {
  selectedCheckId.value = row.item.id
}

const hasMoreData = computed(() => items.value.length > tableItems.value.length)
const headers = [
  { key: 'poNumber', title: t('entity.check.field.poNumber'), width: 100, sortable: false },
  { key: 'serialNumber', title: t('entity.check.field.serialNumber'), width: 100, sortable: false },
  { key: 'released', title: t('entity.check.field.released'), width: 25, sortable: false },
  { key: 'updatedAt', title: t('entity.check.field.updatedAt'), width: 25, sortable: false },
  { key: 'value', title: t('entity.check.field.value'), width: 25, sortable: false },
  { key: 'actions', width: 100, sortable: false, align: 'end' },
]
const sort = [{ key: 'updatedAt', order: 'desc' }]
</script>
<template>
  <h1>{{ t('entity.check.plural') }}</h1>

  <data-table
    :items="tableItems"
    :headers="headers"
    :loading="fetchQuery.loading.value"
    :sort-by="sort"
    :has-more-data="hasMoreData"
    density="comfortable"
    @click:row="selectCheck"
  >
    <template #header.actions>
      <data-table-search v-model="searchQuery.containsString" :loading="fetchQuery.loading" />
    </template>
    <template #item.released="{ item }: { item: LocalCheck }">
      <icon-checked :value="item.released" />
    </template>
    <template #item.updatedAt="{ item }: { item: LocalCheck }">
      <date-time :model-value="item.updatedAt" />
    </template>
    <template #item.value="{ item }: { item: LocalCheck }">
      <icon-checked
        v-if="item.__typename == 'ConfirmCheck'"
        :value="(item as ConfirmCheck).confirmed"
      />
      <article-quantity
        v-if="item.__typename == 'NumericCheck'"
        :milli-quantity="(item as NumericCheck).milliQuantity"
      />
      <markdown
        v-if="item.__typename == 'StringCheck'"
        :text="(item as StringCheck).text"
        :clip-around="50"
      />
    </template>
    <template #item.actions="{ item }: { item: LocalCheck }"> </template>
  </data-table>

  <async-delete-dialog ref="deleteDialog" hindrance />
  <check-dialog v-model="selectedCheckId" />
</template>

<style scoped lang="scss"></style>
