<script setup lang="ts" generic="T">
import { useI18n } from 'vue-i18n'
import { ref } from 'vue'
import useAsyncDialog from '@/components/dialogs/useAsyncDialog'
import { Validation } from '@vuelidate/core'
import NotificationSaved from '@/components/notifications/NotificationSaved.vue'

const props = defineProps<{
  type: 'create' | 'update'
  validation?: Validation
}>()

const { t } = useI18n()
const { openAsyncDialog, asyncDialogResolve, asyncDialogLoading, asyncDialogIsOpen } =
  useAsyncDialog<() => Promise<void>>()

const mutation = ref<() => Promise<unknown>>()
const open = async (mutationArg: () => Promise<unknown>) => {
  mutation.value = mutationArg
  props.validation?.$reset()
  return openAsyncDialog()
}

const notifySaved = ref(false)
const save = async () => {
  props.validation?.$touch()
  if (props.validation?.$invalid || !asyncDialogResolve.value) {
    return
  }

  asyncDialogResolve.value(async () => {
    if (!mutation.value) {
      return
    }

    await mutation.value()
    notifySaved.value = true
  })
}

defineExpose({ open })
</script>

<template>
  <template v-if="asyncDialogResolve">
    <v-dialog v-model="asyncDialogIsOpen" max-width="650" v-bind="$attrs" scrollable>
      <v-card>
        <v-card-title>
          <slot name="title">
            {{ t(`component.upsertDialog.title.${props.type}`) }}
          </slot>

          <v-btn
            density="compact"
            icon="close"
            class="float-right"
            variant="flat"
            @click="asyncDialogResolve()"
          />
        </v-card-title>
        <v-card-text>
          <slot name="default" />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="asyncDialogResolve()">
            {{ t(`button.cancel`) }}
          </v-btn>
          <v-btn color="primary" variant="elevated" :loading="asyncDialogLoading" @click="save">
            {{ t(`button.save`) }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>

  <notification-saved v-model="notifySaved" />
</template>

<style scoped lang="scss"></style>
