import { Marked } from 'marked'

const marked = new Marked({
  async: false,
  gfm: true,
  breaks: true,
})
const parse = (text: string) => marked.parse(text) as string

export default function useMarkdown() {
  return { parseMarkdown: parse }
}
